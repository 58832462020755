@import 'assets/scss/muse';
@import 'assets/scss/rating';
@import 'assets/scss/font-resizer';

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: 'Poppins-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #000;
	color: #ffffff;
	overflow-x: hidden;

	.header-text-bold {
		font-family: 'Poppins-Bold';
		font-style: normal;
		font-weight: bold;
		line-height: 121.4%;
		color: white;

		&.font-55px {
			font-size: 55px;
		}

		&.font-20px {
			font-size: 20px;
		}

		&.font-24px {
			font-size: 24px;
		}

		&.font-17px {
			font-size: 17px;
		}
	}

	.header-text-regular {
		font-family: 'Poppins-Regular';
		font-style: normal;
		font-weight: normal;
		line-height: 121.4%;
		color: white;

		&.font-55px {
			font-size: 55px;
		}

		&.font-17px {
			font-size: 17px;
		}

		&.font-16px {
			font-size: 16px;
		}

		&.font-14px {
			font-size: 14px;
		}

		&.font-11px {
			font-size: 11px;
		}
	}

	::-webkit-scrollbar {
		width: 5px;
	}

	::-webkit-scrollbar-track {
		background: #0e1420;
	}

	::-webkit-scrollbar-thumb {
		background: #0e76bc;
		border-radius: 5px;
	}
}

@font-face {
	font-family: 'Poppins-Regular';
	src: url('./assets//fonts//Poppins-Regular.ttf');
}

@font-face {
	font-family: 'Poppins-Bold';
	src: url('./assets//fonts//Poppins-Bold.ttf');
}

.NoMargin {
	margin: 0;
}

.NoMarginTop {
	margin-top: 0;
}

.MarginBottom5px {
	margin-bottom: 5px;
}

.TextAlignRight {
	text-align: right;
}

.Modal {
	.MuiPaper-root {
		border-radius: 10px;
		background-color: black;
	}
}

.MuiInputLabel-outlined {
	font-size: 14px;
	font-style: normal;
	font-family: Poppins-Regular;
	font-weight: normal;
}

// .MuiMenu-paper {
// 	max-height: 300px !important;
// 	top: 326px !important;
// 	width: 360px !important;
// }
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
	background-color: #4b5371 !important;
}

.MuiTypography-root {
	font-family: 'Poppins-Regular', sans-serif !important;
}

// .MuiMenuItem-root {
// 	background-color: #32374c !important;
// 	color: white !important;
// }
// .MuiMenuItem-root:hover {
// 	background-color: #32374c !important;
// }

.MuiChip-label,
.MuiTypography-root {
	font-family: 'Poppins-Regular', sans-serif !important;
}
.MuiAvatar-root {
	background-color: #32374c;
}
