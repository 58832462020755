.FooterText {
	font-family: 'Poppins-Regular';
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	line-height: 121.4%;
	color: #ffffff;
	text-decoration: none;
}

.SocialMediaImg {
	margin: 10px 5px;
}

.FooterDescription {
	margin-bottom: 10px;
	font-size: 9px;
	opacity: 0.3;
	&:nth-of-type(2) {
		margin-bottom: 40px;
	}
}

.FundImg {
	width: 150px;
	margin: 5px;
}

.FundImgWrapper {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-wrap: wrap;
	img {
		width: 150px;
		height: auto;
	}
}

.bottom {
	max-width: fit-content;
	flex-basis: 100%;
	img {
		max-width: 250px;
	}
}

.bottomContainer {
	justify-content: space-between;
}
