$lightning-size: 18px;

.battery {
  border: 3px solid #333;
  width: 18px;
  height: 28px;
  padding: 2px;
  border-radius: 4px;
  position: relative;
  margin: 15px 0;
  
  &:before {
    content: '';
    height: 3px;
    width: 8px;
    background: #333;
    display: block;
    position: absolute;
    top: -6px;
    border-radius: 4px 4px 0 0;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 1px solid #fff;
    border-radius: 2px;
  }
}

.battery-level {
  background: #30b455;  
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  
  &.warn {
    background-color: #EFAF13;
  }
    
  &.alert {
    background-color: #e81309;
    
    &:before {
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cg%3E%3C%2Fg%3E%20%3Cpath%20fill%3D%22%23e81309%22%20d%3D%22M17.927%2012l2.68-10.28c0.040-0.126%200.060-0.261%200.060-0.4%200-0.726-0.587-1.32-1.314-1.32-0.413%200-0.78%200.187-1.019%200.487l-13.38%2017.353c-0.18%200.227-0.287%200.513-0.287%200.827%200%200.733%200.6%201.333%201.333%201.333h8.073l-2.68%2010.28c-0.041%200.127-0.060%200.261-0.060%200.4%200.001%200.727%200.587%201.32%201.314%201.32%200.413%200%200.78-0.186%201.020-0.487l13.379-17.353c0.181-0.227%200.287-0.513%200.287-0.827%200-0.733-0.6-1.333-1.333-1.333h-8.073z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E'); background-repeat: no-repeat;
  background-size: $lightning-size;
  height: $lightning-size;
  width: $lightning-size;
  margin: -25px 0 0 2px;
      content: '';
      display: inline-block;
      position: absolute;
    }
  }    
}

$grey: #111111;
$white: #fff;
$deep: #000223;
$blue: #01185B;
$mint: #42D6CA;
$black: #000;

.connectBtn {
    width: 100px;
    height: 30px;
    box-sizing: border-box;
    background: none;
    border: none;
    margin: 10px;
}
.connectBtn:focus {
    outline: none;
    opacity: .2;
}

.connectBtn {
    background-color: $mint;
    border: 5px solid $mint;
    transition: all .5s ease;
    &:before {
      content: ' ';
      border: 2px transparent solid;
      position: absolute;
      left: 5px;
      top: 5px;
      box-sizing: border-box;
      width: 110px;
      height: 40px;
      transition: all .7s ease;
    }
}
.connectBtn:hover {
    background-color: rgba($mint, .7);
    color: $white;
    border-radius: 20px;
    &:before {
      border-radius: 20px;
      border: 2px $mint solid;
    }
}

.tempBtn {
  width: 100px;
  height: 30px;
  box-sizing: border-box;
  background: none;
  border: none;
  margin: 10px;
}
.tempBtn:focus {
  outline: none;
  opacity: .2;
}

.tempBtn {
  background-color: $mint;
  border: 5px solid $mint;
  transition: all .5s ease;
  &:before {
    content: ' ';
    border: 2px transparent solid;
    position: absolute;
    left: 5px;
    top: 5px;
    box-sizing: border-box;
    width: 110px;
    height: 40px;
    transition: all .7s ease;
  }
}
.tempBtn:hover {
  background-color: rgba($mint, .7);
  color: $white;
  border-radius: 20px;
  &:before {
    border-radius: 20px;
    border: 2px $mint solid;
  }
}

.museMeasurementsNavigation {
  position: fixed; 
  z-index: 10000; 
  width: 100%; 
  bottom: 0; 
  left: 0;
  right: 0; 
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  padding: 25px;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.museMeasurementsListModal {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .museMeasurementsListModal__Body {
    width: 50%;
    border: 1px solid #fff;
    padding: 15px 15px 30px 15px;
    border-radius: 15px;
    background: rgba($color: #000000, $alpha: 0.2);
    backdrop-filter: blur(6px);

    .museMeasurementsListModal__Close {
      background: rgba($color: #000000, $alpha: 0.6);
      backdrop-filter: blur(6px);
      min-width: auto;
      position: absolute;
      top: -15px;
      right: -15px;
      transition: all 500ms ease-in-out;
      &:hover {
        border-radius: 50%;
        border: 1px solid rgb(255, 255, 255);
        background: rgba(14, 118, 188,1);
      }
    }

    .museMeasurementsListModal__ChooseRecording {
      width: 100%;
      min-width: auto;
    }
  }
}


