.NavLink {
	display: flex;
	align-items: center;
	font-family: 'Poppins-Regular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff;
	text-decoration: none;
	text-transform: inherit;
	padding: 10px;
	margin-right: 5px;
}
