#fontsSizerComponent {
    position: fixed;
    top: 30%;
    right: 0;
    padding: 10px;
    border: 2px solid #FF3354;
    border-radius: 10px 0px 0px 10px;
    transition: all 500ms ease-in-out;
    backdrop-filter: blur(5px);
    background: rgba(255,255,255,0.3);
    z-index: 1000;

    &.hide {
        bottom: 0;
        top: initial;
        #fontSizeArrowUp,
        #fontSizeSmall,
        #fontSizeNormal,
        #fontSizeBig,
        #fontSizeClear {
            display: none
        }
    }

    &.show {
        #fontSizeArrowDown {
            display: none
        }
    }

    @media only screen and (max-width: 600px) {
        right: initial !important;
        left: 0;
        border-radius: 0px 10px 10px 0px !important;
    }

    &:hover {
        background: #FF3354;
        color: #fff;
        a {
            color: #fff;
        }    
    }
}

.fontSizer__Switcher {
    text-align: center; cursor: pointer;
    transition: all 500ms ease-in-out;
    &:hover {
        color: #0E76BC !important;
        transform: scale(1.5);    
    }
}

.fontSizer__Switcher--Clear {
    text-decoration: line-through;
}

#fontSizeSmall {
    font-size: 20px !important;
}

body.fontSizeSmall #fontSizeSmall {
    color: #f00;
}

#fontSizeNormal {
    font-size: 26px !important;
}

body.fontSizeNormal #fontSizeNormal {
    color: #f00;
}

#fontSizeBig {
    font-size: 30px !important;
}

body.fontSizeBig #fontSizeBig {
    color: #f00;
}

#fontSizeClear {
    font-size: 30px !important;
}

body.fontSizeClear #fontSizeBig {
    color: #f00;
}

#fontSizeArrowUp,
#fontSizeArrowDown {
    font-size: 30px !important;
}

body.fontSizeSmall p,
body.fontSizeSmall a,
body.fontSizeSmall li,
body.fontSizeSmall span,
body.fontSizeSmall label,
body.fontSizeSmall div {
    font-size: 14px !important;
}

body.fontSizeSmall h2,
body.fontSizeSmall h3,
body.fontSizeSmall h4 {
    font-size: 16px !important;
} 

body.fontSizeSmall h1 {
    font-size: 16px !important;
} 

body.fontSizeNormal p,
body.fontSizeNormal a,
body.fontSizeNormal li,
body.fontSizeNormal span,
body.fontSizeNormal label,
body.fontSizeNormal div {
    font-size: 18px !important;
}

body.fontSizeNormal h2,
body.fontSizeNormal h3,
body.fontSizeNormal h4 {
    font-size: 19px !important;
} 

body.fontSizeNormal h1 {
    font-size: 20px !important;
} 

body.fontSizeBig p,
body.fontSizeBig a,
body.fontSizeBig li,
body.fontSizeBig span,
body.fontSizeBig label,
body.fontSizeBig div {
    font-size: 28px !important;
}

body.fontSizeBig h2,
body.fontSizeBig h3, 
body.fontSizeBig h4 {
    font-size: 29px !important;
} 

body.fontSizeBig h1 {
    font-size: 30px !important;
}

.arrow {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}
  
.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}
  
.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}