.rating {
	display: inline-block;
	min-width: 120px;
}

.star {
	background: none;
	color: #fff;
	border: none;
	font-size: 1.5em;
	padding: 0 0.15em;
	cursor: pointer;
}

.star.is-active {
	color: #f9dc5c;
}

.screen-reader {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}
