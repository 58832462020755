.NavLink {
	font-family: 'Poppins-Regular';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff;
	text-decoration: none;
	text-transform: uppercase;
	padding: 10px;
	width: 100%;
	@media screen and (min-width: 1024px) {
		width: auto;
	}
}
